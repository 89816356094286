import React, { useState } from "react";

import { navigate } from "gatsby";

import Input from "../Input";
import Button from "../Button";

import * as formStyles from "../Forms/FormStyles.module.css";

const LeadMagnetMini = ({ props, inFooter }) => {
  const {
    title,
    type,
    description,
    image,
    buttonText,
    redirectTo,
    variant,
    code,
  } = props;

  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pot, setPot] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const target = inFooter
    ? "/.netlify/functions/newsletter-signup"
    : "/.netlify/functions/gsheet-leadmagnet-submissions";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (pot === "") {
      const response = await fetch(`${target}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          firstName: name.split(" ")[0],
          lastName: name.split(" ")[1],
          email: email,
          group: code ?? null,
          leadType: type,
          utm_source: localStorage.getItem("utc_source") || "",
          utm_medium: localStorage.getItem("utc_medium") || "",
          utm_content: localStorage.getItem("utc_content") || "",
          utm_campaign: localStorage.getItem("utc_campaign") || "",
        }),
      });

      if (response.status === 200) {
        localStorage.removeItem("utc_source");
        localStorage.removeItem("utc_medium");
        localStorage.removeItem("utc_content");
        localStorage.removeItem("utc_campaign");
        navigate(redirectTo.slug);
      } else {
        setIsProcessing(false);
        setError(true);
        setErrorMessage(
          inFooter
            ? "There was a problem signin up. Please try again."
            : "There was a problem submitting your message. Please try again."
        );
      }
    } else {
      setError(true);
      setErrorMessage(
        inFooter
          ? "There wsa a problem signing up. Please try again."
          : "There was an error submitting the form. Please try again."
      );
    }
  };

  const handleChange = (input, id) => {
    if (id === "pot") {
      setPot(input);
    }
    if (id === "name") {
      setName(input);
    }
    if (id === "email") {
      setEmail(input);
    }
  };

  return (
    <div
      className={`${formStyles.leadForm} ${
        inFooter ? formStyles.inFooter : ""
      }`}
      // data-aos="zoom-in"
      // data-aos-duration="500"
    >
      {title && <h6 style={{ marginBottom: "1rem" }}>{title}</h6>}
      {description && (
        <div
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
        />
      )}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="pot"
          value={pot}
          onChange={handleChange}
          id="pot"
          style={{ display: "none" }}
        />
        <Input
          id="name"
          label="Name:*"
          type="text"
          name="name"
          value={name}
          handleChange={handleChange}
          required
        />
        <Input
          id="email"
          label="Email:*"
          type="email"
          name="email"
          value={email}
          handleChange={handleChange}
          required
        />
        {error && <p className={formStyles.errorText}>{errorMessage}</p>}
        <div className={formStyles.formEnd}>
          <div>
            {/* <Input
        id="optin"
        label="I'm OK with periodical emails from Moonello on Software and Digital Marketing."
        type="checkbox"
        variant={darkTheme ? "dark" : "light"}
        name="optin"
        value={message}
        handleChange={handleChange}
        inline
      /> */}
          </div>
          <div>
            <Button disabled={isProcessing} type="submit">
              {isProcessing ? "Sending..." : buttonText}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );

  /*   return (
    <div
      className={leadMagnetStyles.leadMagnet}
      style={{
        backgroundImage: image ? `url(${image.file.url})` : "",
      }}
      data-aos="fade-in"
    >
      <div className={leadMagnetStyles.veilOfDarkness}></div>
      <div className={leadMagnetStyles.content}>
        <div>
          <h3>{title}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: description ? description.childMarkdownRemark.html : null,
            }}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="pot"
            value={pot}
            id="pot"
            style={{ display: "none" }}
          />
          <Input
            id="name"
            label="Name:*"
            type="text"
            name="name"
            value={name}
            handleChange={handleChange}
            required
          />
          <Input
            id="email"
            label="Email:*"
            type="text"
            name="email"
            value={email}
            handleChange={handleChange}
            required
          />
          {error && <p>{errorMessage}</p>}
          <Button disabled={isProcessing} type="submit" styleAsButton>
            {isProcessing ? "Processing..." : buttonText}
          </Button>
        </form>
      </div>
    </div>
  ); */
};

export default LeadMagnetMini;
