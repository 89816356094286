import React from "react";
import Icon from "../Icon";

const SocialLink = ({ social }) => {
  return (
    <a
      aria-label={`View Moonello on ${social.platform}`}
      href={social.link}
      target="_blank"
      rel="noreferrer"
    >
      <Icon
        props={{
          size: "xl",
          isBrand: true,
          name: social.icon.name,
        }}
      />
    </a>
  );
};

export default SocialLink;
