import React from "react";
import { Link } from "gatsby";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as footerStyles from "./Footer.module.css";
import SocialLink from "../SocialLink";
import LeadMagnetMini from "../LeadMagnet/leadMagnetMini";

const Footer = ({ navigation, company, backgroundColor }) => {
  const logo = navigation.lightLogo && getImage(navigation.lightLogo.file);

  const { footerNewsletter } = company;

  return (
    <footer
      id="footer-main"
      className={`${footerStyles.footer} ${footerStyles[backgroundColor]}`}
    >
      <div
        className={footerStyles.bottomSection}
        // data-aos="fade-up"
        // data-aos-duration="300"
      >
        <div className={footerStyles.linkColumns}>
          <div className={footerStyles.detail}>
            <div>
              <Link to="/">
                <GatsbyImage image={logo} alt="Moonello" className="" />
              </Link>
            </div>

            <div>
              <a href={`tel:${company.phone}`} title="Call us">
                {company.phone}
              </a>
              <br />
              <a href={`mailto:${company.email}`} title="Email us">
                {company.email}
              </a>
              <p
                dangerouslySetInnerHTML={{
                  __html: company.address.childMarkdownRemark.html,
                }}
              />
            </div>
            <div>
              <ul>
                {navigation.pages.map((page, index) => {
                  if (!page.navSubPages) {
                    return (
                      <li key={index}>
                        <Link
                          className={footerStyles.linkColumnHeader}
                          to={page.slug}
                        >
                          <b>{page.navLabel}</b>
                        </Link>
                      </li>
                    );
                  }
                })}
                <li>
                  <Link className={footerStyles.linkColumnHeader} to="/blog/">
                    <b>Blog</b>
                  </Link>
                </li>
                <li>
                  <Link
                    className={footerStyles.linkColumnHeader}
                    to="/resources/"
                  >
                    <b>Resources</b>
                  </Link>
                </li>
              </ul>
            </div>
            {footerNewsletter && (
              <div
                className={`${footerStyles.newsletter} ${footerStyles.smallScreen}`}
                style={{ marginTop: "3rem" }}
              >
                <LeadMagnetMini
                  props={footerNewsletter}
                  company={company}
                  inFooter={true}
                />{" "}
              </div>
            )}
          </div>
          <div className={footerStyles.pages}>
            <ul>
              {navigation.pages.map((page, index) => {
                if (page.navSubPages) {
                  return (
                    <li key={index}>
                      <Link
                        className={footerStyles.linkColumnHeader}
                        to={page.slug}
                      >
                        <b>{page.navLabel}</b>
                      </Link>
                      {page.navSubPages && page.navSubPages.length ? (
                        <ul>
                          {page.navSubPages.map((subPage, index) => (
                            <li key={index}>
                              <Link to={subPage.slug}>{subPage.navLabel}</Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
        <div>
          {footerNewsletter && (
            <div
              className={`${footerStyles.newsletter} ${footerStyles.largeScreen}`}
              style={{ marginTop: "3rem" }}
            >
              <LeadMagnetMini
                props={footerNewsletter}
                company={company}
                inFooter={true}
              />{" "}
            </div>
          )}
        </div>
        <div className={footerStyles.copySocial}>
          <div dangerouslySetInnerHTML={{ __html: company.copyright }} />
          <div className={footerStyles.socialLinks}>
            <ul>
              {company.socialLinks.map((socialLink, i) => (
                <li key={i}>
                  <SocialLink social={socialLink} key={socialLink.name} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
