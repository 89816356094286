// extracted by mini-css-extract-plugin
export var blue = "Footer-module--blue--5057e";
export var bottomSection = "Footer-module--bottomSection--85353";
export var brightBlue = "Footer-module--brightBlue--a3a56";
export var copySocial = "Footer-module--copySocial--1fed2";
export var dark = "Footer-module--dark--cd9f2";
export var darkBlue = "Footer-module--darkBlue--1fce8";
export var darkGray = "Footer-module--darkGray--e6044";
export var detail = "Footer-module--detail--e53be";
export var footer = "Footer-module--footer--519a5";
export var largeScreen = "Footer-module--largeScreen--cee7f";
export var lightGray = "Footer-module--lightGray--5df70";
export var linearBlue = "Footer-module--linearBlue--5eea7";
export var linearDarkBlue = "Footer-module--linearDarkBlue--48604";
export var linearSeaGreen = "Footer-module--linearSeaGreen--685d6";
export var linkColumnHeader = "Footer-module--linkColumnHeader--a1e86";
export var linkColumns = "Footer-module--linkColumns--faff0";
export var newsletter = "Footer-module--newsletter--987a2";
export var offWhite = "Footer-module--offWhite--a6483";
export var pages = "Footer-module--pages--46bfd";
export var seaGreen = "Footer-module--seaGreen--1420f";
export var smallScreen = "Footer-module--smallScreen--b902b";
export var socialLinks = "Footer-module--socialLinks--83a9b";
export var softWhite = "Footer-module--softWhite--c6d9c";
export var transparent = "Footer-module--transparent--49317";
export var white = "Footer-module--white--8ab1f";