import React, { useMemo, useState } from "react";
import * as inputStyles from "./Input.module.css";

const Input = (props) => {
  const {
    id,
    label,
    type,
    name,
    value,
    variant,
    handleChange,
    placeHolder,
    required,
    isTextarea,
    hasMarginTop,
    noMarginBottom,
    inline,
  } = props;

  const [data, setData] = useState(value);

  useMemo(() => {
    setData(value);
  }, [value]);

  const handleUpdate = (e) => {
    setData(e.target.value);
    handleChange(e.target.value, e.target.id, e.target.files);
  };

  // const variantStyles = variant ? inputStyles[`variant-${variant}`] : null;

  return (
    <div
      className={`${inputStyles.form_group} ${
        hasMarginTop ? inputStyles.hasMarginTop : null
      } ${inline ? inputStyles.inline : null}`}
    >
      {isTextarea ? (
        <textarea
          id={id}
          className={`${inputStyles.input} ${
            variant ? inputStyles[variant] : null
          }`}
          name={name}
          value={data}
          placeholder={placeHolder || null}
          type={type}
          rows="5"
          required={required || null}
          onChange={handleUpdate}
        ></textarea>
      ) : (
        <input
          id={id}
          className={`${inputStyles.input} ${
            noMarginBottom ? inputStyles.noMarginBottom : null
          } ${variant ? inputStyles[variant] : null} ${
            inline ? inputStyles.inline : null
          }`}
          name={name}
          value={data}
          placeholder={placeHolder || null}
          type={type}
          required={required || null}
          onChange={handleUpdate}
        />
      )}
      {label !== "" && (
        <label
          className={`${inputStyles.label} ${
            variant ? inputStyles[variant] : null
          }`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default Input;
